import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Kontent_Item_Image_Link } from "../../../graphql-types"
import { ImageLink } from "../image-link/ImageLink"
import { RepeatingImage } from "../repeating-image/RepeatingImage"
import { graphql } from "gatsby"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import "./affiliation-slider.module.scss"

interface AffiliationProps {
  eyebrowText?: string
  heading?: string
  subHeading?: string
  images?: Kontent_Item_Image_Link[]
  backgroundIconUrl?: string
  isFromContentParser?: boolean
}

export const AffiliationSlider = ({
  eyebrowText,
  heading,
  subHeading,
  images,
}: AffiliationProps) => {
  const { size } = useWindowDimensions()
  const getSlides = () => (size > 0 && size < 768 ? 1 : 2)
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: getSlides(),
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  return (
    <section className="affiliation-slider u-content-block-padding">
      <div className="fr-container">
        {(heading || subHeading) && (
          <div className="affiliation-slider__title">
            {eyebrowText && <p className="eyebrow-text">{eyebrowText}</p>}
            {heading && <h2>{heading}</h2>}
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}

        {images && images?.length > 0 && (
          <div className="affiliation-slider__grid">
            {images.map(({ id, elements }) => {
              if (!!elements?.internal_link || !!elements?.url) {
                return <ImageLink key={id} imageLink={elements} />
              } else {
                return <RepeatingImage key={id} images={elements} />
              }
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LogoSliderFragment on kontent_item_logo_slider {
    system {
      type
    }
    id
    elements {
      eyebrow_text {
        value
      }
      logo_slider_heading {
        value
      }
      sub_heading {
        value
      }
      logo_slider {
        value {
          ...SingleImageFragment
          ...ImageLinkFragment
        }
      }
    }
  }
`
