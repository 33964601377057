import React from "react"
import { Link } from "gatsby"
import { ExternalLink } from "../external-link/ExternalLink"
import "./footerCredits.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { useLayout } from "contexts/components/LayoutContext"

interface CreditsProps {
  creditsNavigation: NavigationLinksOutput[]
}

export const Credits = ({ creditsNavigation }: CreditsProps) => {
  const { companyName, footerAdditionalInformation } = useGeneralSettings()
  const { linkedPagesUrlPathCodex } = useLayout()
  let date = new Date().getFullYear()
  return (
    <section className="fr-container credits">
      <div className="credits__content">
        <span>{`© ${companyName}. All Rights Reserved ${date}. `}</span>
        {creditsNavigation.length > 0 && (
          <span className="credits__nav">
            {creditsNavigation?.map((navItem, i) => {
              const { slug, linkText, externalLink } = navItem
              const lastItem = i === creditsNavigation.length - 1

              if (externalLink) {
                return (
                  <React.Fragment key={linkText}>
                    <ExternalLink
                      className="credits__nav-item"
                      href={externalLink}
                    >
                      {linkText}
                    </ExternalLink>
                    {lastItem ? "" : " / "}
                  </React.Fragment>
                )
              } else {
                const path = linkedPagesUrlPathCodex[slug] || "404"
                const newSlug = path.startsWith("/") ? path : `/${path}`
                return (
                  <React.Fragment key={linkText}>
                    <Link to={newSlug} className="credits__nav-item">
                      {linkText}
                    </Link>
                    {lastItem ? "" : " / "}
                  </React.Fragment>
                )
              }
            })}
          </span>
        )}
      </div>
      {footerAdditionalInformation &&
        footerAdditionalInformation.toString() !== "<p><br></p>" && (
          <div className="credits__additional-information">
            <RichTextElement value={footerAdditionalInformation} />
          </div>
        )}
    </section>
  )
}
