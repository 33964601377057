import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BlogCard } from "../BlogCard/BlogCard"
import {
  BlogPostEdge,
  BlogPosts,
  GraphQLBlogPostsData,
} from "./blog-banner-types"
import "./blog-banner.module.scss"
import { useWindowDimensions } from "hooks/useWindowDimensions"

interface BlogBannerProps {
  heading: string
  eyebrowText: string
  subHeading: string
  parentSlug: string
  relatedArticles: BlogPostEdge[]
}

export const BlogBanner = ({
  heading,
  eyebrowText,
  subHeading,
  relatedArticles,
  parentSlug = "",
}: BlogBannerProps) => {
  const { posts } = useStaticQuery<GraphQLBlogPostsData>(
    getBlogBannerFooterPosts
  )

  const getRelatedArticles = (relatedArticles: BlogPostEdge[]) => {
    if (relatedArticles.length > 0) {
      return relatedArticles.map(({ node }: any) => {
        const { id, elements } = node

        const { featured_text, heading, published_date, slug } = elements
        return (
          <BlogCard
            key={id}
            heading={heading?.value}
            slug={slug?.value}
            description={featured_text?.value}
            date={published_date?.value}
            hasDateTag
          />
        )
      })
    }
  }

  const getBlogArticles = (posts: BlogPosts) => {
    const { size } = useWindowDimensions()
    if (posts?.edges?.length === 0) return null
    const postsToReturn =
      size >= 1600 ? posts?.edges?.slice(0, 3) : posts?.edges?.slice(0, 2)
    return postsToReturn.map(({ node }) => {
      const { id, elements } = node
      const { featured_text, heading, published_date, slug } = elements
      return (
        <BlogCard
          key={id}
          heading={heading?.value}
          slug={slug?.value}
          description={featured_text?.value}
          date={published_date?.value}
          hasDateTag
        />
      )
    })
  }

  return (
    <section className={`fr-blog-banner`}>
      <div className="fr-container fr-container--large fr-blog-banner__wrapper">
        {heading && (
          <div className="fr-blog-banner__heading-container">
            {eyebrowText && <p className="eyebrow-text">{eyebrowText}</p>}
            <h2 className="fr-blog-banner__heading">
              {relatedArticles.length > 0 ? "Recommended" : heading}
            </h2>

            {relatedArticles.length === 0 && subHeading && (
              <h3>{subHeading}</h3>
            )}
          </div>
        )}

        <div className="fr-blog-banner__blog-list">
          {relatedArticles.length > 0
            ? getRelatedArticles(relatedArticles)
            : getBlogArticles(posts)}
        </div>
      </div>
    </section>
  )
}

const getBlogBannerFooterPosts = graphql`
  query getBlogBannerFooterPosts {
    posts: allKontentItemBlogPost(
      sort: { fields: elements___published_date___value, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          elements {
            heading {
              value
            }
            featured_text {
              value
            }
            featured_image {
              value {
                description
                height
                name
                url
                width
              }
            }
            published_date {
              value(formatString: "MM/DD/YYYY")
            }
            slug {
              value
            }
          }
        }
      }
    }
  }
`

export const query = graphql`
  fragment BlogBannerFragment on kontent_item_global_footer_blog_banner {
    id
    elements {
      eyebrow_text {
        value
      }
      blog_banner_heading {
        value
      }
      sub_heading {
        value
      }
    }
  }
`
