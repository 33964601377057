import React from "react"
import { AnchorLink } from "components/anchor-link/AnchorLink"
import { useLayout } from "contexts/components/LayoutContext"
import { useSideNav } from "hooks/useSideNav"
import { Button } from "."
import { Variant } from "./buttons-types"

interface AnchorButtonParserProps {
  slug: string
  reference: string
  variant: Variant
  className: string
  extraClassNames: string
  children: React.ReactNode
}

export const AnchorButtonParser = ({
  slug,
  reference,
  variant,
  className,
  extraClassNames,
  children,
}: AnchorButtonParserProps) => {
  const { handleCloseNav, isNavOpen } = useSideNav()
  const { slug: layoutSlug, linkedPagesUrlPathCodex } = useLayout()

  const pageSlug = slug || linkedPagesUrlPathCodex[layoutSlug]

  const anchorLinkSlug = pageSlug?.startsWith("/")
    ? `${pageSlug}#${reference}`
    : `/${pageSlug}#${reference}`

  if (reference.includes("form")) {
    const handleModal = () => {
      if (isNavOpen) {
        handleCloseNav()
      }
    }

    return (
      <Button
        variant={variant}
        extraClassNames={extraClassNames}
        onClick={handleModal}
      >
        {children}
      </Button>
    )
  }

  if (!slug || slug === layoutSlug) {
    return (
      <AnchorLink
        to={anchorLinkSlug}
        className={className}
        onAnchorLinkClick={handleCloseNav}
      >
        {children}
      </AnchorLink>
    )
  }

  // Layout has a useEffect to handle initial load of pages with a hash. E.g: /commercial-pest-control#bpco-container
  return (
    <Button
      to={anchorLinkSlug}
      variant={variant}
      extraClassNames={extraClassNames}
      onClick={handleCloseNav}
    >
      {children}
    </Button>
  )
}
