import { OfficeOutput } from "contexts/settings/GeneralSettingsContext/generalSettings-types"
import React from "react"

interface FooterLocationProps {
  office: OfficeOutput
}

export const FooterLocation = ({ office }: FooterLocationProps) => {
  const { address_line_1, address_line_2, city, state, zip, phone, name, id } =
    office

  const address1 = address_line_1 ? address_line_1 : ""
  const address2 = address_line_2 ? ` ${address_line_2.trim()}` : ""
  const address = `${address1}${address2}`

  const local = `${city}${state ? `, ${state}` : ""} ${zip}`

  return (
    <div className="fr-footer__address" key={id}>
      {name && (
        <p>
          <span className="fr-footer__company-name">{name}</span>
        </p>
      )}
      {address && <p>{address}</p>}
      {local && <p>{local}</p>}
      {phone && <p>{phone}</p>}
    </div>
  )
}
