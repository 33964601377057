import React, { useCallback, useMemo } from "react"
import { useLayout } from "contexts/components/LayoutContext"
import { ButtonBuilder } from "_types/Button"
import { CTA } from "./CTA"
import { Navigation } from "./Navigation"
import { Variant } from "./buttons-types"

interface ButtonParserProps {
  buttons: ButtonBuilder[]
  ctaColorVariantSolid?: Variant
  ctaColorVariantOutline?: Variant
  navigationColorVariantSolid?: Variant
  navigationColorVariantOutline?: Variant
  isSingleNavButtonAlwaysOutline?: boolean
  showIcon?: boolean
  isSingleCTAButtonAlwaysOutline?: boolean
  extraClassNames?: string
}

export const ButtonParser = ({
  buttons,
  showIcon = true,
  extraClassNames = "",
  ctaColorVariantSolid = "solid-primary",
  ctaColorVariantOutline = "outline-primary",
  navigationColorVariantSolid = "solid-secondary",
  navigationColorVariantOutline = "outline-secondary",
  isSingleNavButtonAlwaysOutline = false,
  isSingleCTAButtonAlwaysOutline = false,
}: ButtonParserProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()

  const buttonsTypes = useMemo(
    () =>
      buttons.map(({ elements, system }) => {
        const componentType = system.type
        const {
          linked_page,
          external_link,
          use_main_office_call_tracking_number: useMainOfficeNumber,
          custom_call_tracking_number,
          cta_override,
        } = elements

        const isCTAOverride = cta_override?.value[0]?.codename === "yes"

        const isAnchorButton = componentType === "anchor_button"

        const hasExternalLink = !!external_link?.value

        const useMainOfficeTrackingNumber =
          useMainOfficeNumber?.value[0]?.codename === "yes"
        const customPhoneNumber = custom_call_tracking_number?.value ?? ""
        const hasPhoneNumber = !!customPhoneNumber

        const slugValue = linked_page?.value[0]?.elements?.slug?.value || "404"
        const slug = linkedPagesUrlPathCodex[slugValue]
        const isContactPage = slug.includes("contact")

        switch (true) {
          case isCTAOverride:
          case isAnchorButton:
          case useMainOfficeTrackingNumber || hasPhoneNumber:
          case isContactPage:
            return "cta"
          case hasExternalLink:
            return "navigation"
          default:
            return "navigation"
        }
      }),
    [buttons]
  )
  const getCTAVariant = useCallback(
    (index: number) => {
      if (isSingleCTAButtonAlwaysOutline && buttonsTypes.length === 1) {
        return ctaColorVariantOutline
      }

      const areAllCta = buttonsTypes.every(type => type === "cta")

      if (areAllCta) {
        const isFirst = index === 0
        return isFirst ? ctaColorVariantSolid : ctaColorVariantOutline
      }

      return ctaColorVariantSolid
    },
    [buttonsTypes, isSingleCTAButtonAlwaysOutline]
  )

  const getNavigationVariant = useCallback(
    (index: number) => {
      if (isSingleNavButtonAlwaysOutline && buttonsTypes.length === 1) {
        return navigationColorVariantOutline
      }

      const areAllNavigation = buttonsTypes.every(type => type === "navigation")

      if (areAllNavigation) {
        const isFirst = index === 0
        return isFirst
          ? navigationColorVariantOutline
          : navigationColorVariantSolid
      }

      return navigationColorVariantOutline
    },
    [buttonsTypes, isSingleNavButtonAlwaysOutline]
  )

  return (
    <>
      {buttons.map(({ id, elements, system }, index) => {
        const componentType = system.type as "anchor_button" | "button"
        const { button_text, linked_page, external_link } = elements
        const slug = linked_page?.value[0]?.elements?.slug?.value || "404"

        const buttonType = buttonsTypes[index]

        switch (buttonType) {
          case "cta":
            return (
              <CTA
                componentType={componentType}
                elements={elements}
                extraClassNames={extraClassNames}
                index={index}
                key={id}
                label={button_text?.value}
                onGetCTAVariant={getCTAVariant}
                showPhoneIcon={showIcon}
                slug={slug}
              />
            )
          case "navigation":
          default:
            return (
              <Navigation
                externalLink={external_link?.value}
                extraClassNames={extraClassNames}
                index={index}
                key={id}
                label={button_text?.value}
                onGetNavigationVariant={getNavigationVariant}
                slug={slug}
              />
            )
        }
      })}
    </>
  )
}
