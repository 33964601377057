type Breakpoint = Partial<
  Record<
    "mobile" | "tablet" | "laptop",
    {
      quality?: number
      width?: number
    }
  >
>

type GetBackgroundImageUrlByBreakpointParams = {
  variableName: string
  imageUrl: string
  format?: "pjpg" | "webp" | "jpg" | "png"
  breakpoints?: Breakpoint
}

export function getBackgroundImageUrlByBreakpoint({
  variableName,
  imageUrl,
  format = `pjpg`,
  breakpoints,
}: GetBackgroundImageUrlByBreakpointParams) {
  const backgroundImageCSSVariable = {} as { [key: string]: string }

  backgroundImageCSSVariable[
    `--${variableName}-mobile`
  ] = `url(${imageUrl}?fm=${format}&auto=format&lossless=1&q=${
    breakpoints?.mobile?.quality ?? 50
  }&w=${breakpoints?.mobile?.width ?? 480})`

  backgroundImageCSSVariable[
    `--${variableName}-tablet`
  ] = `url(${imageUrl}?fm=${format}&auto=format&lossless=1&q=${
    breakpoints?.tablet?.quality ?? 50
  }&w=${breakpoints?.tablet?.width ?? 768})`

  backgroundImageCSSVariable[
    `--${variableName}-laptop`
  ] = `url(${imageUrl}?fm=${format}&auto=format&lossless=1&q=${
    breakpoints?.laptop?.quality ?? 50
  }&w=${breakpoints?.laptop?.width ?? 1366})`

  backgroundImageCSSVariable[
    `--${variableName}-desktop`
  ] = `url(${imageUrl}?fm=${format}&auto=format&lossless=1&q=${
    breakpoints?.laptop?.quality ?? 50
  }&w=${breakpoints?.laptop?.width ?? 2560})`

  return backgroundImageCSSVariable
}
