import React from "react"
import { graphql } from "gatsby"
import { useIntegrationsSettings } from "../../hooks/useIntegrationsSettings"
import { SingleReview } from "../reviews/SingleReview"
import { StaticReview } from "../reviews/StaticReview"
import { GlobalFooterItems } from "./GlobalFooterItem"
import { BlogPostEdge } from "../blog/BlogBanner/blog-banner-types"

interface GlobalFooterProps {
  content: any
  meta?: any
  relatedArticles?: BlogPostEdge[]
}

export const GlobalFooter = ({ content, meta, relatedArticles = [] }: GlobalFooterProps) => {
  const { hasFieldroutesReviews, staticReviews } = useIntegrationsSettings()

  const globalFooterContent = content.value[0].elements.content.modular_content
  const globalFooterItems = globalFooterContent?.filter(item => item?.system?.type !== "review_position")
  const reviewBanner = globalFooterContent?.find(item => item?.system?.type === "review_position")

  const getReview = () => {
    if (hasFieldroutesReviews) {
      return <SingleReview />
    }

    if (staticReviews.length > 0) {
      return <StaticReview staticReviews={staticReviews} />
    }
    return null
  }
  
  return (
    <article>
      {reviewBanner && 
        (getReview())
      }
      {globalFooterItems?.map(({ id, elements }, i: number) => {
          return (
            <GlobalFooterItems
              key={`${id}-${i}`}
              blocks={elements}
              meta={meta}
              relatedArticles={relatedArticles}
            />
          )
      })}
    </article>
  )
}

export const query = graphql`
  fragment GlobalFooterFragment on kontent_item_global_footer {
    id
    elements {
      content {
        ...GlobalFooterItemsFragment
      }
    }
  }
`
