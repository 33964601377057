import React from "react"
import { Link } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { Credits } from "./Credits"
import { SocialIcons } from "components/social-icons/SocialIcons"
import { FooterLocation } from "./FooterLocation"
import "./footer.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { ImageBuilder } from "_types/AssetsImage"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { ArrowRight } from "./ArrowRight"
import { Button } from "components/button"

interface FooterProps {
  logo: ImageBuilder
  creditsNavigation: NavigationLinksOutput[]
  footerNavigation: NavigationLinksOutput[]
  handleOpenFooterLocation?: () => void
  hasCTAOtherLocations?: boolean
  customTrackingNum?: string
  isLandingPage: boolean
}

export const Footer = ({
  logo,
  creditsNavigation,
  handleOpenFooterLocation,
  hasCTAOtherLocations = false,
  customTrackingNum,
  isLandingPage,
}: FooterProps) => {
  const {
    customCTAText,
    socialMedia,
    trackingNumbers,
    formattedOffices,
    usePhoneNumberNotTrackingNumber,
    globalFormStubBanner,
  } = useGeneralSettings()

  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber

  const { size } = useWindowDimensions()
  const mainOffice = formattedOffices[0]

  return (
    <footer
      className={`fr-footer ${
        globalFormStubBanner ? `fr-footer--stubbanner` : ""
      }`}
    >
      <div className=" fr-container fr-footer__wrapper">
        <div className="fr-footer__logo-wrapper">
          <Link to="/" className="fr-footer__logo">
            <img
              src={`${logo.url}?q=60&w=300`}
              alt={logo.description}
              loading="lazy"
            />
          </Link>
          {size >= 1024 && (
            <div className="fr-footer__credits-container">
              <Credits creditsNavigation={creditsNavigation} />
            </div>
          )}
        </div>
        <div className="fr-footer__call-wrapper">
          <p className="fr-footer__cta">{customCTAText}</p>
          {contactNumber && (
            <a href={`tel: ${isLandingPage && customTrackingNum ? customTrackingNum : contactNumber}`} className="fr-footer__phone">
              {isLandingPage && customTrackingNum ? customTrackingNum : contactNumber}
            </a>
          )}
          {socialMedia && (
            <div className="fr-footer__social-wrapper">
              {socialMedia.map(
                ({
                  linkUrl,
                  mediaOutlet,
                  customIcon,
                  customOutletName,
                  id,
                }) => {
                  return (
                    <SocialIcons
                      key={id}
                      href={linkUrl}
                      socialMediaOutlet={mediaOutlet}
                      customOutletName={customOutletName}
                      customIcon={customIcon}
                      whereAmI="--footer"
                    />
                  )
                }
              )}
            </div>
          )}
        </div>
        {formattedOffices && mainOffice && (
          <div className="fr-footer__main-office">
            <FooterLocation office={mainOffice} />
            {hasCTAOtherLocations && formattedOffices.length > 1 && (
              <Button
                children="See other Locations"
                extraClassNames="fr-footer__other-locations"
                variant="text-button"
                rightIcon={ArrowRight}
                onClick={handleOpenFooterLocation}
              />
            )}
          </div>
        )}
      </div>
      {size > 0 && size < 1024 && (
        <div className="fr-footer__credits-wrapper">
          <Credits creditsNavigation={creditsNavigation} />
        </div>
      )}
    </footer>
  )
}
