import React, { ReactNode } from "react"
import { Link } from "gatsby"
import HamburgerMenu from "./HamburgerMenu"
import { ImageBuilder } from "../../_types/AssetsImage"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import "./header.module.scss"
import { fullWidthClassName } from "react-remove-scroll-bar"
import { useSideNav } from "hooks/useSideNav"

interface HeaderProps {
  logo: ImageBuilder
  phone?: string
  sticky?: boolean
  children?: ReactNode
  hasToggleMenu?: boolean
  isLandingPage?: boolean
  isAlertEnabled?: boolean
}

export const Header = ({
  logo,
  children,
  isLandingPage = false,
  isAlertEnabled = false,
}: HeaderProps) => {
  const { size, height, isScrolled } = useWindowDimensions()
  const { isNavOpen } = useSideNav()
  const smallerThanLaptop = size > 0 && size < 1366
  const CLASS_CENTER_HEADER = `u-flex-center-align`
  const CLASS_PREVENT_HORIZONTAL_SHIFT_WITH_SIDENAV_OPEN = `u-unset-margin ${
    smallerThanLaptop ? fullWidthClassName : ""
  }`
  const scrollClass = isScrolled
    ? `fr-header--scroll-version ${
        isNavOpen ? "u-change-right-to-accomodate-scroll-bar-position" : ""
      }`
    : ""

  const alertBannerModifier = isAlertEnabled
    ? "fr-header--alert-banner-enabled"
    : ""
  return (
    <header
      className={
        !isLandingPage
          ? `fr-header ${alertBannerModifier} ${CLASS_CENTER_HEADER} ${scrollClass}`
          : `fr-header ${CLASS_CENTER_HEADER} fr-header--landing-page`
      }
    >
      <div
        className={`fr-container fr-container--large fr-header__inner ${CLASS_PREVENT_HORIZONTAL_SHIFT_WITH_SIDENAV_OPEN}`}
      >
        {!isLandingPage && (
          <Link to="/" className={`fr-header__logo`}>
            <img
              src={`${logo.url}?q=60&w=200`}
              alt={logo.description}
              loading="eager"
            />
          </Link>
        )}
        {isLandingPage && (
          <img
            src={`${logo.url}?q=60&w=200`}
            alt={logo.description}
            loading="eager"
            className="base-logo"
          />
        )}
        <div className="fr-header__right">
          {children}
          {!isLandingPage && <HamburgerMenu />}
        </div>
      </div>
    </header>
  )
}
