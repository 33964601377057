import React, { ReactNode, useRef, useState } from "react"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { FocusOn } from "react-focus-on"
import { navigate } from "@reach/router"
import { Button } from "../button"
import { LeftChevron } from "../blog/InternalPagination/Arrows"
import "./modal.module.scss"

interface ModalProps {
  children: ReactNode
  variant: "solid-single-column" | "mix-2-columns"
  onClose: () => void
  isGated?: boolean
  bgColor?: string
}

const VARIANT_CLASSNAME = {
  "solid-single-column": "fr-modal-container__content--solid-single-column",
  "mix-2-columns": "fr-modal-container__content--mix-2-columns",
}

export const Modal = ({
  children,
  onClose,
  variant,
  isGated = false,
  bgColor,
}: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false)
  const variantClassName = VARIANT_CLASSNAME[variant]

  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleGoBack = () => {
    if (typeof history !== "undefined") {
      navigate(-1)
    } else {
      navigate("/")
    }
  }

  const handleOnClose = () => {
    setIsClosing(true)
    let mounted = true
    setTimeout(() => {
      if (mounted && onClose) {
        onClose()
      }
    }, 150)
  }

  return (
    <div role="dialog" className="fr-modal-container">
      {!isGated && (
        <button
          className="fr-modal-container__close-button"
          onClick={handleOnClose}
          ref={buttonRef}
        >
          <IoIosCloseCircleOutline />
        </button>
      )}

      <FocusOn
        shards={[buttonRef]}
        onClickOutside={!isGated ? handleOnClose : undefined}
        onEscapeKey={!isGated ? handleOnClose : undefined}
      >
        <div
          className={`fr-modal-container__content ${variantClassName} ${
            isClosing ? "fr-modal-container__content--hide" : ""
          }`}
          style={{ bgColor } && { backgroundColor: bgColor }}
        >
          {isGated && (
            <Button
              variant="flashy-icon"
              extraClassNames="fr-modal-container__back-button"
              onClick={handleGoBack}
            >
              <LeftChevron />
              Back
            </Button>
          )}
          {children}
        </div>
      </FocusOn>
      <div
        className={`fr-modal-container__overlay ${
          isClosing ? "fr-modal-container__overlay--hide" : ""
        }`}
        aria-label="Modal overlay"
      />
    </div>
  )
}
