import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useExternalScripts } from "hooks/useExternalScripts"
import { appendScript } from "assets/helper/appendScript"
import { PestroutesHelper } from "assets/helper/setTrackingCookies"
import { FieldsParser } from "./FieldsParser"
import {
  InitialFormValues,
  ValidationObject,
  Fields,
} from "./formBuilder-types"
import { graphql } from "gatsby"
import { FormFieldsContext } from "contexts/FormFieldsContext"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { FormErrorMessage } from "./FormErrorMessage"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { FormSubmissionMessage } from "./FormSubmissionMessage"
import { Button } from "../button"
import "./form-builder.module.scss"
import "./ppc-form-builder.module.scss"
import { getInitialFormValues } from "./utils/getInitialFormValues"
import { FormBuilderContext } from "contexts/components/FormBuilderContex"
import { FormFieldGroup } from "./FormFieldGroup"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { CmsAssetImage } from "_types/AssetsImage"

interface FormBuilderProps {
  eyebrowText?: string
  heading?: string
  subHeading?: string
  fields: Fields
  formGridStyle?: string
  ctaText?: string
  isBoxed?: boolean
  submissionMessageHeading?: string
  submissionMessageBody?: string
  customId?: string
  background?: CmsAssetImage
  isLandingPage?: boolean
  isGlobalFooter?: boolean
  customTrackingNumber?: string
}

export const FormBuilder = ({
  eyebrowText,
  heading,
  subHeading,
  fields,
  formGridStyle = "n1_column_layout",
  ctaText,
  submissionMessageHeading,
  submissionMessageBody,
  isBoxed,
  isGlobalFooter = false,
  customId,
  background,
  isLandingPage = false,
  customTrackingNumber,
}: FormBuilderProps) => {
  const formRef = useRef<HTMLFormElement>(null)

  const { isRecaptchaScriptsLoaded, handleSetRecaptcha } = useExternalScripts()
  const { fullName, email } = useContext(FormFieldsContext)
  const { siteUrl, recapKey } = useGeneralSettings()

  const [hasError, setHasError] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)

  const initialFormValues = {} as InitialFormValues
  const validationObject = {} as ValidationObject

  const reCaptchaFocus = useCallback(() => {
    appendScript({
      id: "recaptcha",
      scriptToAppend: `https://www.google.com/recaptcha/api.js?render=${recapKey}`,
      isAsync: true,
    })

    // remove focus to avoid js error:
    // Uncaught Error: reCAPTCHA has already been rendered in this element at Object.kh
    formRef.current &&
      formRef.current.removeEventListener("focus", reCaptchaFocus, true)

    handleSetRecaptcha(true)
  }, [recapKey, formRef, handleSetRecaptcha])

  useEffect(() => {
    if (
      firstRender &&
      !isRecaptchaScriptsLoaded &&
      !(window as any).grecaptcha
    ) {
      formRef.current?.addEventListener("focus", reCaptchaFocus, true)
    } else {
      setFirstRender(true)
    }
  }, [isRecaptchaScriptsLoaded, firstRender, reCaptchaFocus])

  fields?.modular_content.length > 0 &&
    getInitialFormValues({
      fields: fields.modular_content,
      validationObject,
      initialFormValues,
      fullName,
      email,
    })

  const handleOnSubmit = async (values: InitialFormValues) => {
    if (!formSubmitting) {
      setFormSubmitting(true)
      ;(window as any).grecaptcha.ready(() => {
        ;(window as any).grecaptcha
          .execute(recapKey, { action: "submit" })
          .then((token: string) => {
            let myHeaders = new Headers()
            myHeaders.append("Content-Type", "text/plain")
            myHeaders.append("Referer", `${siteUrl}`)
            let raw = JSON.stringify({
              ...values,
              "google-recaptcha-token": token,
              campaignTracking: {
                source: PestroutesHelper.getCookie("utm_source"),
                campaign: PestroutesHelper.getCookie("utm_campaign"),
                medium: PestroutesHelper.getCookie("utm_medium"),
                term: PestroutesHelper.getCookie("utm_term"),
                content: PestroutesHelper.getCookie("utm_content"),
              },
            })
            let params: RequestInit = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            }
            fetch(
              "https://control-prod.lobstermarketing.com/lobster-sites/contact-us",
              params
            )
              .then(response => response)
              .then(response => {
                if (response.status >= 200 && response.status < 300) {
                  console.log("Lead Success: Status ", response.status)
                  setHasFormSubmitted(true)
                  setFormSubmitting(false)
                  setHasError(false)
                  if (window.dataLayer) {
                    window.dataLayer.push({'event':'FormSubmitConversionEvent',});
                  }
                } else {
                  console.log("status", response.status)
                  console.log("error", response)
                  setHasError(true)
                  setFormSubmitting(false)
                }
              })
              .catch(error => {
                console.log("error", error)
                setHasError(true)
                setFormSubmitting(false)
              })
          })
          .catch((error: unknown) => {
            console.log("error", error)
            setHasError(true)
            setFormSubmitting(false)
          })
      })
    }
  }

  const backgroundImageCSSVariable = background?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "form-background-image",
        imageUrl: background.value[0].url,
        breakpoints: {
          mobile: {
            width: 801,
          },
        },
      })
    : {}
  const sectionBgClassName = isGlobalFooter
    ? "fr-form-builder__patterned-background"
    : "default-background-color"
  const boxedWrapperModifier = isBoxed
    ? " fr-form-builder__wrapper--is-boxed"
    : ""

  const getFormHeader = (isBoxed?: boolean) => {
    if (isBoxed) {
      const eyebrowTextWithoutHeadingModifier = !heading
        ? "fr-form-builder__boxed-heading-container--orphan-eyebrow-text"
        : ""
      return (
        <div
          className={`fr-form-builder__boxed-heading-container ${eyebrowTextWithoutHeadingModifier}`}
        >
          <div className="fr-form-builder__boxed-header">
            {eyebrowText && <p className="eyebrow-text">{eyebrowText}</p>}
            {heading && <h2>{heading}</h2>}
            {subHeading && subHeading !== "<p><br></p>" && (
              <RichTextElement value={subHeading} />
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="fr-form-builder__heading-container">
          <h2>{heading}</h2>
          {subHeading && <RichTextElement value={subHeading} />}
        </div>
      )
    }
  }
  const formId = customId || "form-banner"
  return (
    <section
      className={
        isLandingPage
          ? `fr-form-builder ppc`
          : `fr-form-builder u-content-block-padding`
      }
      id={formId}
      style={backgroundImageCSSVariable}
    >
      <div className={`fr-form-builder__inner ${sectionBgClassName}`}>
        {isLandingPage && heading && (
          <h2 className={`ppc__heading`}> {heading} </h2>
        )}
        {!isLandingPage && (heading || eyebrowText) && getFormHeader(isBoxed)}
        <div
          className={
            isLandingPage
              ? `ppc__landing-form-inner ${
                  hasFormSubmitted ? "fr-form-builder__wrapper--hide" : ""
                } `
              : `fr-form-builder__wrapper${boxedWrapperModifier} fr-container ${
                  hasFormSubmitted ? "fr-form-builder__wrapper--hide" : ""
                }`
          }
        >
          {hasError && <FormErrorMessage />}

          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={Yup.object(validationObject)}
            onSubmit={values => handleOnSubmit(values)}
          >
            {({ errors, touched }) => {
              return (
                <FormBuilderContext.Provider value={{ errors, touched }}>
                  <Form ref={formRef}>
                    <div className={formGridStyle}>
                      {fields?.modular_content?.map(
                        ({ id, elements, __typename }) => {
                          if (!elements) return
                          if (__typename === "kontent_item_form_field") {
                            const fieldType = elements?.field_type?.value
                              ? elements.field_type.value[0]?.codename!
                              : ""

                            return (
                              <FieldsParser
                                key={id}
                                fieldType={fieldType}
                                elements={elements}
                              />
                            )
                          } else if (
                            __typename === "kontent_item_form_field_group"
                          ) {
                            return (
                              <FormFieldGroup
                                key={id}
                                sectionDetails={elements.section_details}
                                fields={elements.fields}
                              />
                            )
                          }
                        }
                      )}
                    </div>
                    <Button
                      type="submit"
                      variant="solid-primary"
                      extraClassNames="fr-form-builder__button"
                    >
                      {!formSubmitting ? ctaText : "Submitting..."}
                    </Button>
                  </Form>
                </FormBuilderContext.Provider>
              )
            }}
          </Formik>
        </div>
        {hasFormSubmitted && (
          <FormSubmissionMessage
            className={`fr-form-builder__wrapper${boxedWrapperModifier} fr-container`}
            heading={submissionMessageHeading}
            subHeading={submissionMessageBody}
          />
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FormFragment on kontent_item_form {
    system {
      type
    }
    id
    elements {
      eyebrow_text {
        value
      }
      heading {
        value
      }
      sub_heading {
        links {
          link_id
          url_slug
        }
        value
      }
      submission_message_body {
        value
      }
      submission_message_heading {
        value
      }
      submit_button_text {
        value
      }
      fields {
        modular_content {
          ...FormFieldFragment
          ...FormFieldGroupFragment
        }
      }
      form_column_layout {
        value {
          codename
        }
      }
      is_boxed_layout {
        value {
          codename
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      custom_id {
        value
      }
    }
  }
`
