import React from "react"
import { ImageBuilder } from "../../_types/AssetsImage"
import { Button } from "../button"
import { TestimonialSkeleton } from "../skeleton/Testimonial/TestimonialSkeleton"
import { Testimonial } from "./Testimonial"
import "./testimonial.module.scss"

interface TestimonialBannerProps {
  review: string
  rating?: string
  reviewerName: string
  reviewerLocation: string
  hasFieldroutesReviews?: boolean
  hasReviewLoaded?: boolean
  backgroundImageUrl?: string
  avatar?: ImageBuilder
  googleReviewUrl?: string
  heading?: string
  eyebrowText?: string
}

export const TestimonialBanner = ({
  review,
  rating,
  reviewerName,
  reviewerLocation,
  eyebrowText,
  hasFieldroutesReviews = false,
  avatar,
  googleReviewUrl,
  backgroundImageUrl,
  heading,
  hasReviewLoaded = true,
}: TestimonialBannerProps) => {
  return (
    <section
      className={`u-content-block-padding fr-testimonial ${
        hasFieldroutesReviews ? "fr-testimonial--fadeIn" : ""
      }`}
    >
      <div className="fr-container fr-testimonial__wrapper">
        {heading && (
          <div className={`text-content-block__title`}>
            {eyebrowText && <p className={`eyebrow-text`}>{eyebrowText}</p>}
            <h2 className="fr-testimonial__heading">{heading}</h2>
          </div>
        )}
        {hasReviewLoaded ? (
          <Testimonial
            hasFieldroutesReviews={hasFieldroutesReviews}
            reviewerName={reviewerName}
            review={review}
            avatar={avatar}
            reviewerLocation={reviewerLocation}
          >
            {hasFieldroutesReviews && hasReviewLoaded && (
              <Button
                variant={"outline-secondary"}
                to="/reviews"
                extraClassNames="fr-testimonial__button"
              >
                Read All Reviews
              </Button>
            )}
          </Testimonial>
        ) : (
          <TestimonialSkeleton hasAvatar={!!avatar} hasRating={false} />
        )}
      </div>
    </section>
  )
}
