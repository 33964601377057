import React from "react"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import "./testimonial.module.scss"
import { ImageBuilder } from "../../_types/AssetsImage"
import { Image } from "../image/Image"

interface TestimonialProps {
  review: string
  rating?: string
  reviewerName: string
  reviewerLocation: string
  hasFieldroutesReviews?: boolean
  avatar?: ImageBuilder
  children?: React.ReactNode
}

export const Testimonial = ({
  review,
  rating,
  reviewerName,
  reviewerLocation,
  hasFieldroutesReviews = false,
  avatar,
  children,
}: TestimonialProps) => {
  return (
    <div
      className={`fr-testimonial__body ${
        hasFieldroutesReviews
          ? "fr-testimonial__body--fadeIn"
          : "fr-testimonial__body"
      }`}
    >
      {avatar && (
        <Image
          image={avatar}
          alt={avatar.description}
          className="fr-testimonial__avatar fr-testimonial__avatar"
          width={avatar.width}
          height={avatar.height}
          objectFit="cover"
        />
      )}
      <div className="fr-testimonial__body-content">
        {hasFieldroutesReviews && <p>"{review}"</p>}
        {!hasFieldroutesReviews && (
          <div className="fr-testimonial__body-content--paragraph">
            <RichTextElement value={review} />
          </div>
        )}
        <div className="fr-testimonial-reviewer">
          {rating && (
            <span className="fr-testimonial-reviewer__rating-stars">
              {rating}
            </span>
          )}
          <div className="fr-testimonial-reviewer__info">
            <strong>{reviewerName}</strong>
            {reviewerLocation && <span>{reviewerLocation}</span>}
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
