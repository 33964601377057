import React from "react"
import { truncString } from "assets/helper/truncString"
import { ImageBuilder } from "_types/AssetsImage"
import { BlogCardContainer } from "./BlogCardContainer"
import "./blog-card.module.scss"
import { useLayout } from "contexts/components/LayoutContext"

interface BlogCardProps {
  image?: ImageBuilder
  heading?: string
  description?: string
  slug: string
  hasButton?: boolean
  hasDateTag?: boolean
  hasDateTitle?: boolean
  date?: string
  customOverlayCTAText?: string
}

export const BlogCard = ({
  heading,
  description,
  slug,
  date,
}: BlogCardProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const shortenedDescription = description ? truncString(description, 155) : ""
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`
  const longTitle = heading && heading.length >= 100 ? true : false

  return (
    <BlogCardContainer hasButton={false} slug={path}>
      <div className="fr-blog-card__body">
        <h2 className="fr-blog-card__heading">{heading}</h2>

        {date && (
          <time className="fr-blog-card__published-date-text" dateTime={date}>
            {date}
          </time>
        )}
        {!longTitle && (
          <p className="fr-blog-card__description">{shortenedDescription}</p>
        )}
        <p className="fr-blog-card__read-more">Read More</p>
      </div>
    </BlogCardContainer>
  )
}
