import React from "react"
import { AnchorNumber } from "components/secondary-header/AnchorNumber"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { MobilePhoneIcon } from "assets/helper/icons"

interface PhoneIconProps {
  isTrackingModalOpen: boolean
  handleOpenTrackingModal: () => void
  isLandingPage: boolean
  customTrackingNum?: string
}

export const PhoneIcon = ({
  isTrackingModalOpen,
  handleOpenTrackingModal,
  isLandingPage,
  customTrackingNum,
}: PhoneIconProps) => {
  const { trackingNumbers, usePhoneNumberNotTrackingNumber } =
    useGeneralSettings()

  const addHiddenNumbersToDOM = () => {
    trackingNumbers.map(({ trackingNumber, phoneNumber, id, label }) => {
      if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
      if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
      return (
        <AnchorNumber
          key={id}
          classNameModifier="--hidden"
          phoneNumber={phoneNumber}
          trackingNumber={trackingNumber}
          label={label}
          showIcon={false}
        />
      )
    })
  }
  const getPhone = () => {
    if (trackingNumbers.length === 1 || customTrackingNum) {
      const { trackingNumber, phoneNumber } = trackingNumbers[0]
      if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
      if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
      return (
        <a href={`tel: ${isLandingPage && customTrackingNum ? customTrackingNum : trackingNumber}`} className="fr-header__phone">
          <MobilePhoneIcon />
        </a>
      )
    } else {
      return (
        trackingNumbers.length >= 2 && (
          <>
            <button
              className="fr-header__phone"
              onClick={handleOpenTrackingModal}
            >
              <MobilePhoneIcon />
            </button>
            {!isTrackingModalOpen && addHiddenNumbersToDOM()}
          </>
        )
      )
    }
  }
  return <>{getPhone()}</>
}
