import React, { useMemo } from "react"
import { shuffle } from "../../assets/helper/shuffle"
import { StaticReviewOutput } from "../../contexts/settings/Integrations/integrationsSettings-types"
import { TestimonialBanner } from "../testimonial/TestimonialBanner"

interface StaticReviewProps {
  staticReviews: StaticReviewOutput[]
}

export const StaticReview = ({ staticReviews }: StaticReviewProps) => {
  const getRating = (rating: string) => {
    switch (rating) {
      case "n1_star":
        return "★"
      case "n2_stars":
        return "★★"
      case "n3_stars":
        return "★★★"
      case "n4_stars":
        return "★★★★"
      case "n5_stars":
        return "★★★★★"
      default:
        return ""
    }
  }
  const randomReview = useMemo(() => {
    const review = shuffle(staticReviews)
    return review
  }, [staticReviews])

  const { review, reviewer, location, heading, reviewImage, eyebrowText } =
    randomReview

  return (
    <TestimonialBanner
      reviewerLocation={location}
      review={review}
      reviewerName={reviewer}
      avatar={reviewImage}
      heading={heading}
      eyebrowText={eyebrowText}
    />
  )
}
