import React from "react"

export const getPhoneIcon = (modifier = "", customClassName = "") => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20.336"
      height="22"
      viewBox="0 0 20.336 22"
      className={`${
        customClassName ? customClassName : `phone-svg${modifier}`
      }`}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_986"
            data-name="Rectangle 986"
            width="20.336"
            height="22"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_2294"
        data-name="Group 2294"
        transform="translate(0 0)"
        opacity="1"
      >
        <g
          id="Group_2293"
          data-name="Group 2293"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_14575"
            data-name="Path 14575"
            d="M16.183,22A15.351,15.351,0,0,1,4.254,16.21C-.259,10.506-.567,4.662.535,1.383l.109-.322L.959.935A13.216,13.216,0,0,1,7.4.093l.376.045L7.96.471A10.748,10.748,0,0,1,9.112,7.327L9.066,7.6l-.219.175A15.128,15.128,0,0,1,6.541,9.307a10.532,10.532,0,0,0,1.937,3.08,10.7,10.7,0,0,0,2.649,2.5,15.036,15.036,0,0,1,1.945-1.969l.214-.18.28.009a10.758,10.758,0,0,1,6.5,2.454l.266.219v.344a10.714,10.714,0,0,1-1.675,5.778l-.184.289-.34.045A15.01,15.01,0,0,1,16.183,22M1.83,2.174c-.391,1.455-1.367,6.88,3.556,13.1A13.8,13.8,0,0,0,17.6,20.459a9.236,9.236,0,0,0,1.261-4.347,9.3,9.3,0,0,0-5.063-1.89,13.66,13.66,0,0,0-1.869,2.009l-.32.423-.5-.172a9.459,9.459,0,0,1-3.763-3.161A9.54,9.54,0,0,1,4.969,9.015L4.9,8.489l.477-.233A13.644,13.644,0,0,0,7.707,6.81a9.279,9.279,0,0,0-.861-5.3,11.753,11.753,0,0,0-5.016.663"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export const MobilePhoneIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.068"
      height="24.515"
      viewBox="0 0 22.068 24.515"
    >
      <path
        d="M16.208,24c-1.455,0-5.394-.717-10.947-7.291a23.886,23.886,0,0,1-2.678-3.551A15.221,15.221,0,0,1,0,5.129l0-.05C.023,4.968.4,2.339,3.78.245A2.2,2.2,0,0,1,5.4.085,3.4,3.4,0,0,1,7.36,1.848C8.652,4.148,9.4,5.551,9.4,5.565l.027.05.015.055A2.973,2.973,0,0,1,9.12,7.845,2.85,2.85,0,0,1,7.1,9.049a.681.681,0,0,0-.433.375c-.112.264-.178.91.646,2.265,1.806,2.969,4.849,5.163,5.453,5.224l.123.012.048.032c.111,0,.718-.112,1.863-1.718a1.56,1.56,0,0,1,1.373-.718c2.146.081,4.959,4.632,5.273,5.151l.04.066.018.075a2.815,2.815,0,0,1-.477,2.119c-.738,1.071-2.163,1.752-4.236,2.024a3.012,3.012,0,0,1-.581.048M.987,5.188c-.135,5.423,4.945,10.8,5,10.857l.02.023c6.5,7.7,10.554,6.935,10.593,6.925l.046-.008c1.755-.228,2.986-.778,3.558-1.59a1.874,1.874,0,0,0,.353-1.29c-1.095-1.788-3.217-4.553-4.42-4.6a.586.586,0,0,0-.535.306c-1.644,2.305-2.648,2.237-3.043,2.064C11.343,17.618,8.2,15.04,6.47,12.2c-.812-1.335-1.047-2.408-.7-3.188a1.651,1.651,0,0,1,1.151-.932A1.929,1.929,0,0,0,8.288,7.32,1.951,1.951,0,0,0,8.5,5.966c-.154-.288-.862-1.612-2-3.637C5.508.559,4.441,1.01,4.284,1.09c-2.82,1.755-3.254,3.845-3.3,4.1"
        transform="translate(0.25 0.261)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.5"
      />
    </svg>
  )

export const getLoginIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
    >
      <g id="icon" transform="translate(-2.627 -0.001)" opacity="1">
        <path
          id="Path_14710"
          data-name="Path 14710"
          d="M17.467,20H1.489a.891.891,0,0,1,0-1.766h15.2V1.766H1.489A.832.832,0,0,1,.717.885.835.835,0,0,1,1.489,0H17.467a.84.84,0,0,1,.775.885V19.118a.84.84,0,0,1-.775.885"
          transform="translate(4.384 0.001)"
          fill="#fff"
        />
        <path
          id="Path_14711"
          data-name="Path 14711"
          d="M13.872,4.384H.757A.829.829,0,0,1,0,3.5a.825.825,0,0,1,.757-.881H13.872a.827.827,0,0,1,.755.881.83.83,0,0,1-.755.885"
          transform="translate(2.627 6.361)"
          fill="#fff"
        />
        <path
          id="Path_14712"
          data-name="Path 14712"
          d="M4.408,11.025a.883.883,0,0,1-.624-1.509L7.059,6.241,3.808,2.994A.884.884,0,0,1,5.056,1.742L8.931,5.617a.884.884,0,0,1,0,1.252l-3.9,3.9a.88.88,0,0,1-.624.257"
          transform="translate(8.73 3.608)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const getBurgerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.861"
      height="34"
      viewBox="0 0 55.861 34"
    >
      <g transform="translate(2 2)">
        <g transform="translate(13.443 10.564)">
          <path
            d="M1572.537,1342.061a4.2,4.2,0,1,1,.089-8.394,4.2,4.2,0,1,1-.089,8.394Z"
            transform="translate(-1568.299 -1333.667)"
            fill="#feca7a"
          />
          <path
            d="M1639.863,1342.062a4.2,4.2,0,1,1,.114-8.394,4.2,4.2,0,1,1-.114,8.394Z"
            transform="translate(-1619.726 -1333.668)"
            fill="#feca7a"
          />
          <path
            d="M1711.569,1337.828a4.233,4.233,0,0,1-4.181,4.234,4.2,4.2,0,1,1-.077-8.393A4.227,4.227,0,0,1,1711.569,1337.828Z"
            transform="translate(-1671.152 -1333.668)"
            fill="#feca7a"
          />
        </g>
        <line
          y2="30"
          fill="none"
          stroke="#feca7a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  )
}
export const getSideNavCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.933"
      height="24.93"
      viewBox="0 0 24.933 24.93"
    >
      <path
        id="close-icon"
        d="M288.582,309.783l8.9-8.9-8.906-8.9,3.56-3.56,8.906,8.9,8.906-8.906,3.561,3.561-8.907,8.906,8.9,8.9-3.56,3.559-8.9-8.9-8.9,8.9Z"
        transform="translate(-288.58 -288.413)"
        fill="#004880"
      />
    </svg>
  )
}
