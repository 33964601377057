import React, { ReactNode } from "react"
import { Link } from "gatsby"
import "./blog-card.module.scss"

interface BlogCardProps {
  slug?: string
  hasButton?: boolean
  children?: ReactNode
}

export const BlogCardContainer = ({ slug = "", children }: BlogCardProps) => {
  return (
    <Link to={slug} className="fr-blog-card-link  fr-blog-card--box-shadow">
      <article className="fr-blog-card">{children}</article>
    </Link>
  )
}
