import { graphql } from "gatsby"
import React from "react"
import { AffiliationSlider } from "../affiliation-slider/AffiliationSlider"
import { BlogPostEdge } from "../blog/BlogBanner/blog-banner-types"
import { BlogBanner } from "../blog/BlogBanner/BlogBanner"
import { CTABanner } from "../cta-banner/CTABanner"
import { FormBuilder } from "../forms/FormBuilder"

interface GlobalFooterItemsProps {
  blocks: any
  meta?: any
  relatedArticles: BlogPostEdge[]
}

export const GlobalFooterItems = ({
  blocks,
  meta,
  relatedArticles,
}: GlobalFooterItemsProps) => {
  const {
    cta_heading,
    description,
    body,
    background_image,
    button,
    eyebrow_text,
    logo_slider_heading,
    logo_slider,
    blog_banner_heading,
    sub_heading,
    form_column_layout,
    fields,
    custom_id,
  } = blocks

  const getCtaBanner = () => {
    if (cta_heading) {
      return (
        <CTABanner
          background={background_image?.value[0]}
          heading={cta_heading.value}
          description={description?.value}
          body={body}
          buttons={button}
        />
      )
    }
  }

  const getFormBanner = () => {
    const customId = custom_id?.value === "" ? "form-banner" : custom_id?.value
    if (fields) {
      return (
        <FormBuilder
          eyebrowText={blocks?.eyebrow_text?.value}
          heading={blocks?.heading?.value}
          subHeading={blocks?.sub_heading?.value}
          ctaText={blocks?.submit_button_text?.value}
          submissionMessageHeading={blocks?.submission_message_heading?.value}
          submissionMessageBody={blocks?.submission_message_body?.value}
          fields={fields}
          background={background_image}
          formGridStyle={form_column_layout.value[0].codename}
          isBoxed={true}
          customId={customId}
          isGlobalFooter
        />
      )
    }
  }

  const getAffiliationBanner = () => {
    if (logo_slider_heading) {
      return (
        <AffiliationSlider
          eyebrowText={eyebrow_text?.value}
          heading={logo_slider_heading?.value}
          subHeading={sub_heading?.value}
          images={logo_slider?.value}
        />
      )
    }
  }

  const getBlogBanner = () => {
    if (blog_banner_heading) {
      return (
        <BlogBanner
          eyebrowText={eyebrow_text.value}
          heading={blog_banner_heading.value}
          subHeading={sub_heading.value}
          relatedArticles={relatedArticles}
          parentSlug={meta && meta.parentSlug ? meta.parentSlug : ""}
        />
      )
    }
  }
  return (
    <>
      {getCtaBanner()}
      {getFormBanner()}
      {getAffiliationBanner()}
      {getBlogBanner()}
    </>
  )
}

export const query = graphql`
  fragment GlobalFooterItemsFragment on kontent_item_rich_text_element_value {
    modular_content {
      system {
        type
      }
      ...BlogBannerFragment
      ...CTABannerFragment
      ...FormFragment
      ...LogoSliderFragment
    }
  }
`
