import React from "react"
import { getBurgerIcon } from "../../assets/helper/icons"
import { useSideNav } from "../../hooks/useSideNav"
import "./header.module.scss"

const HamburgerMenu = () => {
  const { isNavOpen, handleOpenNav, handleCloseNav } = useSideNav()

  const toggleNav = () => {
    if (!isNavOpen) {
      return handleOpenNav
    } else {
      return handleCloseNav
    }
  }

  return (
    <button
      onClick={toggleNav()}
      className="fr-header__burger"
      aria-label="open side menu"
    >
      {getBurgerIcon()}
    </button>
  )
}

export default HamburgerMenu
